import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";
import Swal from "sweetalert2";



class GameRates extends React.Component {

    constructor() {
        super();
        this.state = {
            code: "",
            refer_perc: "0",
        }
    }

    componentDidMount() {


        let form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));

        axios
            .post(process.env.REACT_APP_URL+"/api2/get_profile.php", form,
            )
            .then((response) => {
                if(response.status === 200){
                    this.setState({
                        code:response.data.code,
                        refer_perc:response.data.refer_perc
                    })
                } else if(response.status === 401){
                    //  navigate("/login")
                }
            })
    }



    render() {


        let copyText = (e)=>{
            var textField = document.createElement('textarea')
            textField.innerText = e
            document.body.appendChild(textField)
            textField.select()
            document.execCommand('copy')
            textField.remove()

            let timerInterval;
            Swal.fire({
            html: "Copied to clipboard.",
            timer: 2000,
            timerProgressBar: true,
            willClose: () => {
                clearInterval(timerInterval);
            }
            })
          }

        let sendWhatsApp = () => {
            let msg = "Download "+process.env.REACT_APP_NAME+" now from link - https://ds.satta-num.in/signup?REFER="+this.state.code+" , Use refer code to signup:- "+this.state.code;
        
            let url = "https://api.whatsapp.com/send?text=" + msg;
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null

            
           
          };


        return (<>
                <div className="RootView">
                    <div className="RootWidth">

                        <div className="loginDiv">


                            <div className="toolbar">
                                <div>
                                    <img src="../images/back.png" alt="back" onClick={()=>{window.history.go(-1)}} style={{padding: "5px"}}/>
                                    <span>Refer & Earn</span>
                                </div>
                            </div>
                            { this.state.refer_perc !== "0" ? (
                            <div  className="refer-parent">
                                <div>
                                    <img style={{height:"99px"}} src="../images/refer.png" />
                                </div>
                                <div className="refer-text">
                                    <span>Refer your friends and earn {this.state.refer_perc}% commission on their deposits</span>
                                </div>
                            </div>
                                ) : "" }

                            <div style={{padding:"5px"}} className="frameForm">

                                <div className="main-game-screen">
                                    <span style={{paddingBottom:"0px"}}>Referral Code</span>

                                    <p style={{display:"flex", marginTop:"5px" ,justifyContent:"space-between", alignItems:"center", background:"#4c0080", color:"white", border:"solid 2px #f0ffb6"}}
                                     onClick={()=>{copyText( this.state.code )}} className="openAppButton">
                                        <p
                                        style={{marginBottom:"0", textAlign:"center", width:"70%", fontSize:"20px", fontWeight:"600"}}
                                        >{ this.state.code }</p><span style={{marginLeft:"20px",minWidth:"80px", fontSize:"12px", background:"#fff", padding:"5px", color:"black", borderRadius:"5px", paddingRight:"11px", border:"solid 1px #1a2c4c8a"}}>
                                            <img src="../images/copy-min.png" />COPY</span>
                                    </p>
                                       
                                    <p style={{textAlign:"center", fontWeight:"600", fontSize:"13px"}} className="error-msg">COPY OR SHARE YOUR REFERRAL CODE WITH FRIENDS</p>
                                    
                                    <div className="shareby" onClick={()=>{sendWhatsApp()}}>
                                        <img src="../images/phone (2)-min.png" />
                                        <span>SHARE VIA WHATSAPP</span>
                                    </div>
                                    
                                   <div style={{marginTop:"30px"}}>
                                   <span >
                                    <span className="horizontal-shake">👉</span>
                                     How to refer a friend ?
                                    </span>
                                    <p className="refer-p" style={{marginTop:"5px"}}>1. Copy the referral code and share it with your friend.</p>
                                    <p className="refer-p">2. Ask your friend to register on App using the referral code.</p>
                                   </div>
                                </div>

                              

                            </div>

                        </div>


                    </div>
                </div>


            </>
        );
    }

}

export default GameRates;
