import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";
import Swal from "sweetalert2";


class GameRates extends React.Component {

    constructor() {
        super();
        this.state = {
            single: "-",
            double: "-"
        }


    }

    componentDidMount() {


        var form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));

        axios
            .post(process.env.REACT_APP_URL+"/api2/delhi_markets.php", form,
            )
            .then((response) => {
                if(response.status === 200){
                    if (response.data.nosession){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: "Session Expired"
                        }).then((e)=>{
                            localStorage.clear()
                            window.location.href = "/login";
                        })
                    }
                    this.setState({
                        single:response.data.single,
                        double:response.data.jodi
                    })
                } else if(response.status === 401){
                    //  navigate("/login")
                }
            })
    }


    render() {


        return (<>
                <div className="RootView">
                    <div className="RootWidth">

                        <div className="loginDiv">


                            <div className="toolbar">
                                <div>
                                    <img src="../images/back.png" onClick={()=>{window.history.go(-1)}} style={{padding: "5px"}}/>
                                    <span>Game Rates</span>
                                </div>


                            </div>

                            <div style={{padding:"16px"}}>

                                <div className="upi_apps" style={{flexDirection:"column", marginTop:"0"}}>
                                    <div className="edittext think-border" style={{width:"100%", marginLeft:"0", marginRight:"0",marginBottom:"10px", background:"#4c0080", color:"white"}}>
                                        <span className="activeButton" style={{fontSize:"17px", fontWeight:"600", background:"white", color:"#4c0080"}}>{this.state.single}</span>
                                        <span style={{marginTop:"10px", fontSize:"18px", fontWeight:"500"}}>ANDAR BAHAR</span>
                                    </div>
                                    <div className="edittext think-border" style={{width:"100%", marginLeft:"0", marginRight:"0", background:"#4c0080", color:"white"}}>
                                    <span className="activeButton" style={{fontSize:"17px", fontWeight:"600", background:"white", color:"#4c0080"}}>{this.state.double}</span>
                                    <span style={{marginTop:"10px", fontSize:"18px", fontWeight:"500"}}>Jodi</span>
                                    </div>
                                </div>

                            </div>

                        </div>


                    </div>
                </div>


            </>
        );
    }

}

export default GameRates;
