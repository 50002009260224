import { useCallback , useState } from "react";
import {Navigate, useNavigate} from "react-router-dom";
import styles from "../style/Login.module.css"
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import {bindActionCreators} from "redux";
import {actions} from "../state/index"

const Login = () => {
    const navigate = useNavigate();

    const state = useSelector(state => state.session)

    const dispatch = useDispatch()
    const {setSession, getSession} = bindActionCreators(actions, dispatch)

    const [mobileNumber, setMobileNumber] = useState("");
    const [pass, setPassword] = useState("");

    const baseurl = process.env.REACT_APP_URL;

    let loginSubmit = (event) =>{
        event.preventDefault();

        var form = new FormData();
        form.append("mobile", mobileNumber);

        axios
            .post(`${baseurl}/api2/forgot_pass.php`, form)
            .then((response) => {
                if(response.data.success === "0"){
                    Swal.fire({
                        toast: true,
                        title: 'Oops...',
                        text: response.data.msg
                    })
                } else {
                    navigate("/resetPassword", { state:{mobile:mobileNumber} })
                    // navigate({
                    //     pathname: "/resetPassword",
                    //     search: createSearchParams({
                    //         mobile:mobileNumber
                    //     }).toString()
                    // })
                }
            })

        if (state){
            return <Navigate to={"/home"} />
        }
    }

    const onForgotClick = useCallback(() => {
        navigate("/forgot");
    }, [navigate]);

    return (
        <div className="RootView">
            <div className="RootWidth authBack">
                <div className={styles.loginDiv}>
                    <img className="logoIcon" alt="" src="../images/logo.png" />

                    <form
                        className={styles.frameForm}
                        method="post"
                        onSubmit={loginSubmit}
                    >
                        <div className="pageHeadings">Forgot Password</div>
                        <input
                            className="edittext"
                            name="mobile"
                            type="number"
                            value={mobileNumber}
                            onChange={(e)=>{setMobileNumber(e.target.value)}}
                            placeholder="Enter your mobile number"
                        />

                        <button className="button">
                            <b>Submit</b>
                        </button>

                    </form>

                </div>
            </div>
        </div>
    );
};

export default Login;
