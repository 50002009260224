import { useCallback , useState } from "react";
import {Link, Navigate, useLocation, useNavigate} from "react-router-dom";
import styles from "../style/Login.module.css"
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import {bindActionCreators} from "redux";
import {actions} from "../state/index"
import { Loading } from "./Loading";


const Login = () => {
    const navigate = useNavigate();

    const location = useLocation();


    const [descr, setdescr] = useState("Enter your mobile number and continue to best experience of gali disawar");

    const state = useSelector(state => state.session)
    const dispatch = useDispatch()
    const {setSession, getSession} = bindActionCreators(actions, dispatch)

    const [mobileNumber, setMobileNumber] = useState("");
    const [pass, setPassword] = useState("");
    const [Name, setName] = useState("");
    const [setOTP, setsetOTP] = useState("");
    const [id, setid] = useState("");
    
    const [cpass, setcpass] = useState("");
    const [ccpass, setccpass] = useState("");
    const [code, setcode] = useState("");
    const [whatsapp, setwhatsapp] = useState("");

    const [signup, setsignup] = useState("none");
    
    const [formstate, setformstate] = useState("none");
    const [showLoading, setshowLoading] = useState("none");
    const [otpstate, setotpstate] = useState("none");
    const [showNumber, setshowNumber] = useState("block");
    
    const [loginStage, setloginStage] = useState("final");

    const baseurl = process.env.REACT_APP_URL;

    let loginSubmit = (event) =>{
        setshowLoading("block");
        event.preventDefault();

    
        if(mobileNumber.length != 10){
            setshowLoading("none");
            Swal.fire({
                toast: true,
                title: 'Oops...',
                text: "Mobile number should be 10 digit long"
            })

        } else if(loginStage == "mobile"){
            var form = new FormData();
            form.append("mobile", mobileNumber);
    
            axios
                .post(`${baseurl}/api2/login_check.php`,form)
                .then((response) => {
                    console.log(response)
                    setwhatsapp("https://wa.me/+91"+response.data.whatsapp)
                    if(response.data.success === "0"){
                        
                        setid(response.data.otp)
                        setshowLoading("none");
                        setdescr("Welcome, create your account entering name and password");
                                
                        setotpstate("none");
                        setsignup("block");
                        setshowLoading("none");
                        setloginStage("otp");
                        // setshowNumber("none");
                        // setotpstate("block");
                        // setloginStage("otp");
                    } else {
                        setshowNumber("none");
                        setshowLoading("none");
                        setformstate("block");
                        setloginStage("password");
                    }
                })
        } else if(loginStage == "otp"){

            if(cpass == ""){
                setotpstate("none");
                setsignup("block");
                setshowLoading("none");
            } else {

                if(cpass == ""){
                    setshowLoading("none");
                    Swal.fire({
                        toast: true,
                        title: 'Oops...',
                        text: "Enter valid password"
                    })

                } else if(cpass !== ccpass){
                    setshowLoading("none");
                    Swal.fire({
                        toast: true,
                        title: 'Oops...',
                        text: "Password does not match"
                    })

                } else {

                    var form = new FormData();
                    form.append("mobile", mobileNumber);
                    form.append("pass", cpass);
                    form.append("refcode", code);
                    form.append("name", Name);
                    form.append("id", id);

                    axios
                        .post(`${baseurl}/api2/register.php`,form)
                        .then((response) => {
                            console.log(response)
                            if(response.data.success === "0"){

                                
                            setshowLoading("none");
                            setotpstate("block");
                            setsignup("none");

                                setshowLoading("none");
                                Swal.fire({
                                    toast: true,
                                    title: 'Oops...',
                                    text: response.data.msg
                                })
                            } else {
                                setshowLoading("none");
                                setSession(response.data.token, mobileNumber)
                                navigate("/home")
                            }
                        })

                    if (state){
                        return <Navigate to={"/home"} />
                    }
                }
            }
        } else {

            var form = new FormData();
            form.append("mobile", mobileNumber);
            form.append("pass", pass);

            axios
                .post(`${baseurl}/api2/login.php`,form)
                .then((response) => {
                    if(response.data.success === "0"){
                        setshowLoading("none");
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: response.data.msg,
                            footer: '<a href='+whatsapp+'>Forgot Password ?</a>'
                        })

                    } else {
                        setshowLoading("none");
                        setSession(response.data.token, mobileNumber)
                        navigate("/home")
                    }
                })

            if (state){
                return <Navigate to={"/home"} />
            }
        }
    }

    let updateMob = (e)=>{
        if(e.length < 11){
            //if(loginStage == "mobile"){
                setMobileNumber(e)
         //   }
        }
    }

    
    const onForgotClick = useCallback(() => {
        navigate("/forgot");
    }, [navigate]);

    return (
        
        <div className="RootView">
            <div className="RootWidth authBack">

            <div style={{display:showLoading}}>
                <div class="loader-parent">
                <div class="loader-block">
                <div class="loader"></div> 
                </div>
                </div>
            </div>
            
            <div className="loginBackDesign">
                
                <h1>Welcome to {process.env.REACT_APP_NAME}</h1>
                <p>{descr}</p>
            </div>
                <div className={styles.loginDiv}>


                <img className="logoIcon" alt="" src="../images/logo.png" />

                    <form
                        className={styles.frameForm}
                        method="post"
                        onSubmit={loginSubmit}
                    >

                        

                        <div className="pageHeadings" style={{fontSize:"16px", marginBottom:"10px"}}>Login your account</div>
                        <input
                            className={ setformstate == "none" ? "edittext" : "edittext-disable" }
                            name="mobile"
                            type="number"
                            minLength="10"
                            maxLength="10"
                            value={mobileNumber}
                            onChange={(e)=>{updateMob(e.target.value)}}
                            placeholder="Enter your mobile number"
                            style={ { display:showNumber}}
                        />
                        
                        <div style={{width:"100%"}}>
                            <input className="edittext" type="password"
                            style={{width:"100%", marginBottom:"10px"}}
                                name="passwword"
                                value={pass}
                                placeholder="Enter your password"
                                onChange={(e)=>{setPassword(e.target.value)}}
                            />

                            <div className={styles.frameDiv}>
                                <a href={whatsapp} className={styles.forgotDiv}>Forgot Password ?</a>
                            </div>

                        </div>

                        <div style={{width:"100%", display:otpstate}}>

                            <p style={{textAlign:"center"}}>OTP sent to your Mobile number</p>
                            
                            <p style={{textAlign:"center"}}>{mobileNumber}</p>

                            <input className="edittext" type="number"
                            style={{width:"100%", marginBottom:"10px"}}
                                name="otp"
                                value={setOTP}
                                placeholder="Enter otp"
                                onChange={(e)=>{setsetOTP(e.target.value)}}
                            />


                        </div>
                        <div style={{width:"100%", display:signup}}>

                         <input className="edittext" type="text"
                            style={{width:"100%", marginBottom:"10px"}}
                                name="Name"
                                value={Name}
                                placeholder="Your Name"
                                onChange={(e)=>{setName(e.target.value)}}
                            />

                            <input className="edittext" type="password"
                            style={{width:"100%", marginBottom:"10px"}}
                                name="cpass"
                                value={cpass}
                                placeholder="Create Password"
                                onChange={(e)=>{setcpass(e.target.value)}}
                            />

                            <input className="edittext" type="password"
                            style={{width:"100%", marginBottom:"10px"}}
                                name="ccpass"
                                value={ccpass}
                                placeholder="Confirm Password"
                                onChange={(e)=>{setccpass(e.target.value)}}
                            />

                            <input className="edittext" type="text"
                                style={{width:"100%", marginBottom:"10px"}}
                                name="code"
                                value={code}
                                placeholder="Promo Code (Optional)"
                                onChange={(e)=>{setcode(e.target.value)}}
                            />

                        </div>

                        <button style={{marginTop:"10px"}} className="button" >
                                SUBMIT
                            </button>

                        <Link style={{width:"100%"}} to="/signup">
                        
                        <button style={{marginTop:"5px", width:"100%"}} className="button" >
                                CREATE NEW ACCOUNT
                            </button>
                            </Link>

                    </form>

                </div>
            </div>
        </div>
    );
};

export default Login;
