import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { usePromiseTracker } from "react-promise-tracker";

import React from 'react';
import Home from "./AppComponents/Home";
import Starline from "./AppComponents/Starline";
import Gali from "./AppComponents/Gali";
import MainBets from "./AppComponents/MainBets";
import Login from "./AppComponents/Login";
import Signup from "./AppComponents/Signup";
import Splash from "./AppComponents/Splash";
import DepositMoney from "./AppComponents/DepositMoney";
import Games from "./AppComponents/Games";
import GameRates from "./AppComponents/GameRates";
import Wallet from "./AppComponents/Wallet";
import SPDPTP from "./AppComponents/SPDPTP";
import Motor from "./AppComponents/Motor";
import FullSangam from "./AppComponents/FullSangam";
import HalfSangam from "./AppComponents/HalfSangam";
import OddEven from "./AppComponents/OddEven";
import RedBracket from "./AppComponents/RedBracket";
import GaliGames from "./AppComponents/GaliGames";
import StarlineGames from "./AppComponents/StarlineGames";
import Profile from "./AppComponents/Profile";
import GameHistory from "./AppComponents/GameHistory";
import WithdrawMoney from "./AppComponents/WithdrawMoney";
import TransferPoints from "./AppComponents/TransferPoints";
import HowToPlay from "./AppComponents/HowToPlay";
import Chart from "./AppComponents/Chart";
import Forgot from "./AppComponents/Forgot";
import ResetPassword from "./AppComponents/ResetPassword";
import RequireAuth from "./AppComponents/RequireAuth";
import GameNotifications from "./AppComponents/GameNotifications";
import Winning from "./AppComponents/Winning";
import Charts from "./AppComponents/Charts";
import GameHistorySelection from "./AppComponents/GameHistorySelection";
import WinningHistorySelection from "./AppComponents/WinningHistorySelection";
import PlayGame from "./AppComponents/PlayGame";
import Refer from "./AppComponents/Refer";
import Transactions from "./AppComponents/Transactions";
import Notice from "./AppComponents/Notice";
import WithdrawHistory from "./AppComponents/WithdrawHistory";
import PaymentSuccess from "./AppComponents/PaymentSuccess";
import PaymentFailed from "./AppComponents/PaymentFailed";

const LoadingIndicator = props => {
  const { promiseInProgress } = usePromiseTracker();

  return (
      promiseInProgress &&
      <div className="backdraw">
        <img src="/src/images/loading.svg" />
      </div>
  );
}

const App = ()=>{

    document.title = process.env.REACT_APP_NAME

  return (
      <>
        <LoadingIndicator/>
        <Router>
          <Routes>
              <Route path="/" element={<Splash />}  />
              
              <Route path="/paymentSuccess" element={<PaymentSuccess />}  />
              <Route path="/paymentFailed" element={<PaymentFailed />}  />

              <Route path="/login" element={<Login />}  />
              <Route path="/signup" element={<Signup />}  />
              <Route path="/forgot" element={<Forgot />}  />
              <Route path="/resetPassword" element={<ResetPassword />}  />

              <Route path="/deposit" element={<RequireAuth><DepositMoney /></RequireAuth>}  />
              <Route path="/wallet" element={<RequireAuth><Wallet /></RequireAuth>}  />
              <Route path="/withdraw" element={<RequireAuth><WithdrawMoney /></RequireAuth>}  />
              <Route path="/transfer" element={<RequireAuth><TransferPoints /></RequireAuth>}  />

              <Route path="/home" element={<RequireAuth><Home /></RequireAuth>}  />
              <Route path="/games" element={<RequireAuth><Games /></RequireAuth>}  />

              <Route path="/charts" element={<RequireAuth><Charts /></RequireAuth>}  />
              <Route path="/gali" element={<RequireAuth><Gali /></RequireAuth>}  />

              <Route path="/order" element={<RequireAuth><MainBets /></RequireAuth>}  />
              <Route path="/play" element={<RequireAuth><PlayGame /></RequireAuth>}  />
              <Route path="/spdptp" element={<RequireAuth><SPDPTP /></RequireAuth>}  />
              <Route path="/motor" element={<RequireAuth><Motor /></RequireAuth>}  />
              <Route path="/fullsangam" element={<RequireAuth><FullSangam /></RequireAuth>}  />
              <Route path="/halfsangam" element={<RequireAuth><HalfSangam /></RequireAuth>}  />
              <Route path="/oddeven" element={<RequireAuth><OddEven /></RequireAuth>}  />
              <Route path="/redbracket" element={<RequireAuth><RedBracket /></RequireAuth>}  />
              <Route path="/refer" element={<RequireAuth><Refer /></RequireAuth>}  />

              <Route path="/gameRates" element={<RequireAuth><GameRates /></RequireAuth>}  />
              <Route path="/profile" element={<RequireAuth><Profile /></RequireAuth>}  />
              <Route path="/gameHistory" element={<RequireAuth><GameHistory /></RequireAuth>}  />
              <Route path="/gameHistorySelection" element={<RequireAuth><GameHistorySelection /></RequireAuth>}  />
              <Route path="/winningHistorySelection" element={<RequireAuth><WinningHistorySelection /></RequireAuth>}  />
              <Route path="/howToPlay" element={<RequireAuth><HowToPlay /></RequireAuth>}  />
              <Route path="/chart" element={<RequireAuth><Chart /></RequireAuth>}  />
              <Route path="/notifications" element={<RequireAuth><GameNotifications /></RequireAuth>}  />
              <Route path="/winnings" element={<RequireAuth><Winning /></RequireAuth>}  />

              <Route path="/gali" element={<RequireAuth><Gali /></RequireAuth>}  />
              <Route path="/galiGames" element={<RequireAuth><GaliGames /></RequireAuth>}  />
              <Route path="/Transactions" element={<RequireAuth><Transactions /></RequireAuth>}  />
              <Route path="/Notice" element={<RequireAuth><Notice /></RequireAuth>}  />


              <Route path="/starline" element={<RequireAuth><Starline /></RequireAuth>}  />
              <Route path="/starlineGames" element={<RequireAuth><StarlineGames /></RequireAuth>}  />
              <Route path="/withdrawHistory" element={<RequireAuth><WithdrawHistory /></RequireAuth>}  />
          </Routes>
        </Router>
      </>
  );
}

export default App;
